import { useEffect } from 'react';
import Default from '../components/layout/Default';
import { Link } from 'react-router-dom';

const Services = (props) => {
  useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Default backgroundImage="/img/services.jpg" backgroundPosition="center bottom">
      <h1 className="pagetitle">Services</h1>
      <h4 className="pagetitle quote">“Gifts of service are my hearts desire, to open up your world and live your best life in Complete wellness”</h4>
      <p>I will provide my services either in person or video call on google meet ( Helps me to hear you by reading captions )</p>

      <div className="service-card">
        <div className="service-card__header">
          <div className="service-card__title">
            <h4>One on One Consultation</h4>
            <h5>One Time (60-90 mins)</h5>
          </div>
          <div className="service-card__price">
            <h3>$175</h3>
          </div>
        </div>
        <div className="service-card__body">
          <p className="service-card__desc">
            This could be a discussion about one particular health problem your struggling with, a second opinion, a specific functional lab, or a detoxification
            protocol. Lifestyle changes, supplement and/ or protocols recommended.
          </p>
        </div>
      </div>

      <div className="service-card-connected-container">
        <div className="service-card-connected-container__header">
          <h4 className="service-card-connected-container__title">
            Integrated Health Package.
            <br />
            Levels 1&2
          </h4>
          <h4 className="service-card-connected-container__price">Total package $420</h4>
        </div>
        <div className="service-card">
          <div className="service-card__header">
            <div className="service-card__title">
              <h4>Appointment 1</h4>
              <h5>(60-90 mins)</h5>
            </div>
            <div className="service-card__price">
              <h3>$150</h3>
            </div>
          </div>
          <div className="service-card__body">
            <p className="service-card__desc">
              Initial meeting gathering your health history intake from a questionnaire I send you prior to meeting and a toxicity test to measure your toxic load. You
              may be offered to do a functional lab. Recommendations will be given on lifestyle, diet and exercise. I will encourage you to do a functional medicine
              detoxification for either 1 week, 2 weeks or 3 weeks depending on your score on the toxicity test.
            </p>
          </div>
        </div>
        <div className="service-card-connected-container__connector" />
        <div className="service-card">
          <div className="service-card__header">
            <div className="service-card__title">
              <h4>Appointment 2</h4>
              <h5>1 month later (60-90 mins)</h5>
            </div>
            <div className="service-card__price">
              <h3>$150</h3>
            </div>
          </div>
          <div className="service-card__body">
            <p className="service-card__desc">
              Review on your wellbeing Wellness plan presented and discussed. Lab results also discussed if you ran one. Recommended supplements/minerals to cover any
              deficiencies and toxicity removal. Protocols to help with imbalances found in history and lab work.
            </p>
          </div>
        </div>
        <div className="service-card-connected-container__connector" />
        <div className="service-card">
          <div className="service-card__header">
            <div className="service-card__title">
              <h4>Appointment 3</h4>
              <h5>1 month later (30 mins)</h5>
            </div>
            <div className="service-card__price">
              <h3>$60</h3>
            </div>
          </div>
          <div className="service-card__body">
            <p className="service-card__desc">Review protocols, status of health, supplement adjustment if needed and more lifestyle advice.</p>
          </div>
        </div>
        <div className="service-card-connected-container__connector" />
        <div className="service-card">
          <div className="service-card__header">
            <div className="service-card__title">
              <h4>Wellness Check</h4>
              <h5>Wellness checks will occur during week 8 and week 13. (30 mins)</h5>
            </div>
            <div className="service-card__price">
              <h3>$60</h3>
            </div>
          </div>
          <div className="service-card__body">
            <p className="service-card__desc">Maintenance check, continued wellness education and reviews of protocols and supplementation</p>
          </div>
        </div>
      </div>
      <div className="center">
        <Link to="/functional-labs">
          <button>Functional Labs</button>
        </Link>
      </div>
    </Default>
  );
};

export default Services;
