import { useEffect } from 'react';
import Default from '../components/layout/Default';
import './home.scss';
import { Link } from 'react-router-dom';

const FAQ = (props) => {
  useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Default backgroundImage="/img/faq.jpg" backgroundPosition="center">
      <h1 className="pagetitle">Questions & Answers</h1>
      <h4 className='question'>1.What is an Integrated Health Practitioner (IHP)?</h4>
      <p>
        An Integrated Health Practitioner is a health coach with an expertise in Ayurvedic Medicine, Chinese Traditional Medicine, Traditional Naturopathy,
        Bio-regulatory, Orthomolecular and Functional Medicine. An integrated health practitioner’s mission is to help seek the clients roots of ill-health, bring balance
        back to the mind, body, emotions and spirit for complete wellness, bringing with it enhanced vitality, ,a state of balance and increased longevity. An IHP cannot
        diagnose, cure or prescribe medications.
      </p>
      <h4 className='question'>2. What is a functional lab test?</h4>
      <p>
        A functional Lab can provide your client with a more complete set of facts on their health markers that may be unbalanced. It reduces the guess work of finding
        the true underlying causes of imbalances in their health. Level 11 Integrative Health Practitioners can recommend and review at-home functional lab tests.
      </p>
      <h4 className='question'>3. Why are supplements recommended?</h4>
      <p>
        Supplements are recommended when vitamin and mineral deficiences show up as specific symptoms and biomarkers in lab work identifying specific deficiences.
        Supplements are recommended to bring balance back into the body and reduce the symptoms. Usually supplements are given for a period of 16 weeks. When symptoms
        start to decline we know the body’s is starting to rebalance. Retesting the biomarkers can also affirm that this process is happening.
      </p>
      <h4 className='question'>4. What is a biomarker on a Lab test?</h4>
      <p>
        Biomarkers are specific values in lab work that measure substances pertinent to a balanced/unbalanced body function. For example; The Candida, Metabolic &
        Vitamins Lab measures; vitamins, enzymes, amino acids, metabolites, bacterial overgrowths, and types of yeasts. The Mineral and Metal Lab measures minerals and
        metal toxicities; The Stress, Mood and Metabolism Lab measures your hormones, insulin, blood sugars and Vitamin D values.
      </p>
      <div className="center">
        <Link to="/functional-labs">
          <button>Functional Labs</button>
        </Link>
      </div>
    </Default>
  );
};

export default FAQ;
