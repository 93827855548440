import React from 'react';
import './styles.scss';
import { useLocation, Link } from 'react-router-dom';
import { useEffect } from 'react';

const links = [
  { to: '/', label: 'Home' },
  { to: '/meet-lisa', label: 'Meet Lisa' },
  { to: '/services', label: 'Services' },
  { to: '/faq', label: 'FAQ' },
  { to: '/contact', label: 'Contact' },
];

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const Default = ({ children, backgroundImage, backgroundPosition, floatingPortrait }) => {
  const location = useLocation();
  const [navOpen, setNavOpen] = React.useState(false);

  useEffect(() => {
    const preloadImages = [
      '/img/certified.png',
      '/img/certified-banner.jpg',
      '/img/contact.jpg',
      '/img/faq.jpg',
      '/img/homepage.jpg',
      '/img/labs.jpg',
      '/img/lets-connect-portrait.png',
      '/img/meet-lisa-portrait.jpg',
      '/img/meet-lisa.jpg',
      '/img/roots-to-wellness-journey.jpg',
      '/img/services.jpg',
      '/img/walk-through-fire.jpg',
      '/img/logo.png',
    ];

    preloadImages.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  }, []);

  return (
    <div className="layout-container">
      <div className="header" style={{ backgroundImage: `url(${backgroundImage})`, backgroundPosition }}>
        {floatingPortrait}
      </div>
      <nav className="navigation">
        <div className="mobile-nav-row">
          <Link to="/" className="logo-and-title">
            <img className="logo" src="/img/logo.png" alt="Logo" />
            <h1>Roots to Wellness</h1>
          </Link>
          <button id="nav-toggle" className="nav-toggle" onClick={() => setNavOpen(!navOpen)}>
            <i className="fa-solid fa-bars"></i>
          </button>
        </div>
        <ul className={navOpen ? 'open' : 'closed'}>
          {links.map((link) => (
            <li key={link.to}>
              <Link to={link.to} className={location.pathname === link.to ? 'active' : ''}>
                {link.label}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <div className="content">
        <ScrollToTop />
        <div className="content-inner">{children}</div>
      </div>
      <footer>
        <div className="footer-content">
          <div className="certified">
            <img src="/img/certified.png" alt="Certified Complimentary Therapist Logo" />
            <div>
              <h2 className="lisa-footer">Lisa Marsh</h2>
              <h5 className='hide-on-small'>Integrated Health Practitioner 1&2</h5>
              <h5 className='hide-on-large'>Integrated Health<br/>Practitioner 1&2</h5>
              <h5>
                <i className="fa-solid fa-envelope"></i>&nbsp;<a href="mailto:lisa@roots2wellness.org">lisa@roots2wellness.org</a>
              </h5>
              <div className="footer-address">
                <div>
                  <i className="fa-solid fa-location-dot"></i>
                </div>
                <div>
                  <h5>145 5th Street East</h5>
                  <h5> Owen Sound, N4K 1C4</h5>
                  <h5>ON, Canada</h5>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Link to="/contact">
              <button style={{ marginTop: 0 }}>Make an Appoinment</button>
            </Link>
          </div>
        </div>
        <div className="footer-accent" />
      </footer>
    </div>
  );
};

export default Default;
