import { useEffect } from 'react';
import Default from '../components/layout/Default';

const Roots = (props) => {
  useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Default backgroundImage="/img/roots-to-wellness-journey.jpg">
      <h1 className="pagetitle">My roots to wellness journey</h1>
      <p>
        I have experienced chemotherapy and Radiation treatments in London Cancer Centre. Chemotherapy had to be stopped after 4 months of treatment. It was killing my
        T-cells quicker than my cancer cells. My tumours grew larger as a result. I was transferred over to radiation therapy. I was told that this plan was a “salvage
        plan”, to give me a bit more time. They believed I was going to die anyway. I was totally shocked and terrified at what they said.
      </p>
      <p>
        I had 18 radiation treatments, some targeted on the tumours and the rest a broad spectrum over my brain and spinal cord. After my treatments I went home. I was
        totally exhausted and very unwell. I had no energy left and very little resilience. I had a fever of 38-39 degrees celsius for an entire week. Intuitively I knew
        my body was fighting against the inflammation the radiation had caused in my cells. I stayed the course and let my body heal.
      </p>
      <p>
        I took immune boosting supplements, teas to boost my immune system and ate an anti-inflammatory diet. I started a course of 50mg IV vitamin C three times a week
        to build up my immune system. I had also been drinking powerful plant based medicines from a medical herbalist in Toronto. I took these in between my chemo
        treatment to help detoxification of the chemicals from my liver. I had reiki energy healing weekly from a support group. I did a daily practise of my indigenous
        visualizations to heal my brain and spine. I also read as much as possible. How to live through cancer and beyond. For example Chris Wark’s Beat Cancer book,
        Radical Remissions by Kelly Turner and Dr Bernie Siegels Love, Medicine and Miracle book. I inwardly digested each word on every page.
      </p>
      <p>
        After three months of all my alternative therapies. I had the strength enough to travel to Tijuana, Mexico by train (3 days across the USA east to west.). I did
        manage to enjoy the scenery, which distracted me from my destination. I had enrolled in a three week immunotherapy program at the Chipsa Hospital. The doctors
        were trained in medicine in the states and then trained in naturopathy in Mexico. My youngest brother Kevin was the instigator of this plan. Another earth Angel,
        like my husband Keith, who never left my side.
      </p>
      <p>
        The three week “Boot Camp” as it was often called included; immunotherapy healing protocols: I chose vitamin C iv infusions 50mg, Ozone therapy, Vitamin K
        therapy. I took part in the Gerson Diet for nutrition, juicing, detoxification with coffee enemas and emotional platforms to help rebalance my body, mind and
        emotions to full healing and wellness.
      </p>
      <p>
        I went into remission in early 2019 after leaving CHIPSA Hospital. I continued my natural methods of healing for the next year, particularly the IV 50mg vitamin
        C. Continued with my vegetarian anti-inflammatory diet and immune boosting supplements. I continued to walk in nature on a daily basis and practice my meditation.
      </p>
      <p>
        Now after reading my wellness story. A cancer survivor. You may be asking “What was the root cause of my illness?” I believe the root cause was chronic stress and
        feelings of deep rooted anger. Before cancer I had always eaten vegetarian nutritious foods, exercised daily, meditated, practised yoga. I was not a smoker or a
        drinker. So being diagnosed with cancer was a surprise to me and all my friends and family.
      </p>
      <p>
        However cancer is also an emotionally, spiritually and mentally based illness, not just a physical manifestation of ill health. Dr Bernie Siegel M.D. who wrote
        his book “Love, Medicine and Miracles” found with his cancer clients that unconditional love is the most powerful stimulant of the immune system. He said “the
        truth is love heals”.His clients who had the courage to love and work with their doctors, influence and participate in their recovery, miracles happened.
      </p>
      <p>
        Having navigated through an emotionally provoking, nasty and vicious divorce and custody battle, which left me feeling hopeless and trampled on. I was very deeply
        angry at, the court system, the lawyers, my ex husband, for all the injustices I had experienced. I was even angry at God for not answering my prayers for true
        justice.The emotion of anger and resentment fuelled my normal healthy cells into cancer cells. Especially feelings of unresolved anger. These cells manifested as
        cancer cells in my body,.which affected my hind brain and spine cerebral spinal fluids.
      </p>
      <p>After having been so close to death, my spirit released all angers and forgiveness flowed deep into my consciousness.</p>

      <h3 className="quote center">“ What matters most is how you walk through the fire”</h3>
      <img style={{marginTop: 8}} src="/img/walk-through-fire.jpg" alt="Roots to Wellness Journey Walk Through Fire" />
    </Default>
  );
};

export default Roots;
