import { useEffect } from 'react';
import Default from '../components/layout/Default';

const Contact = (props) => {
  useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Default
      backgroundImage="/img/contact.jpg"
      floatingPortrait={
        <div className="floating-portrait">
          <img src="/img/lets-connect-portrait.png" alt="Lisa Portrait" />
        </div>
      }
    >
      <h1 className="pagetitle">Lets Connect</h1>
      <div className="pagetitle">
        <h4>You deserve to feel fully well, inside and out, while bringing the body, mind, emotions and spirit back into balance.</h4>
        <h4>I am here to walk with you every step of your health journey to lead you to your best life.</h4>
      </div>
      <p className="center">
        Please fill in the contact form below if you have any further questions and/ or interested in an appointment. I will connect with you as soon as possible
      </p>
      <form action="https://formspree.io/f/manwrdjo" method="POST">
        <input name="name" type="text" className="feedback-input" placeholder="Name" />
        <input name="email" type="email" className="feedback-input" placeholder="Email" />
        <textarea name="message" className="feedback-input" placeholder="Message"></textarea>
        <input type="submit" value="SUBMIT" />
      </form>
    </Default>
  );
};

export default Contact;
