import { useEffect } from 'react';
import Default from '../components/layout/Default';

const Labs = (props) => {
  useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Default backgroundImage="/img/labs.jpg">
      <h1 className="pagetitle">Functional Labs</h1>
      <div className="card-container">
        <div className="card">
          <div className="card-icon">
            <i className="fa-solid fa-mortar-pestle"></i>
          </div>
          <div className="card-title">Candida, Metabolic & Vitamins</div>
        </div>
        <div className="card">
          <div className="card-icon">
            <i className="fa-solid fa-bowl-food"></i>
          </div>
          <div className="card-title">Food Sensitivity Test</div>
        </div>
        <div className="card">
          <div className="card-icon">
            <i className="fa-solid fa-cube"></i>
          </div>
          <div className="card-title">Minerals and metals</div>
        </div>
        <div className="card">
          <div className="card-icon">
            <i className="fa-solid fa-biohazard"></i>
          </div>
          <div className="card-title">Toxic heavy metals</div>
        </div>
        <div className="card">
          <div className="card-icon">
            <i className="fa-solid fa-brain"></i>
          </div>
          <div className="card-title">Mind & Body Neurotransmitter</div>
        </div>
        <div className="card">
          <div className="card-icon">
            <i className="fa-solid fa-bacteria"></i>
          </div>
          <div className="card-title">Gut Bacteria & Parasites</div>
        </div>
        <div className="card">
          <div className="card-icon">
            <i className="fa-solid fa-xmarks-lines"></i>
          </div>
          <div className="card-title">Mold Toxicity</div>
        </div>
        <div className="card">
          <div className="card-icon">
            <i className="fa-solid fa-3"></i>
          </div>
          <div className="card-title">Omega 3 & Inflammation</div>
        </div>
        <div className="card">
          <div className="card-icon">
            <i className="fa-solid fa-sun"></i>
          </div>
          <div className="card-title">Stress, Mood and Metabolism</div>
        </div>
      </div>
      <p className="center" style={{ marginTop: '-10px' }}>
        (see website:{' '}
        <a href="https://equi.life/products" target="_blank" rel="noreferrer">
          equi.life/products
        </a>{' '}
        for more details)
      </p>
      <div style={{ marginBottom: '60px' }} />
      <h1 className="pagetitle">Wellness Goals</h1>
      <div className="card-container">
        <div className="card">
          <div className="card-icon">
            <i className="fa-solid fa-bolt"></i>
          </div>
          <div className="card-title">Increase Energy</div>
        </div>
        <div className="card">
          <div className="card-icon">
            <i className="fa-solid fa-weight-scale"></i>
          </div>
          <div className="card-title">Weight loss</div>
        </div>
        <div className="card">
          <div className="card-icon">
            <i className="fa-solid fa-scale-balanced"></i>
          </div>
          <div className="card-title">Rebalance hormones</div>
        </div>

        <div className="card">
          <div className="card-icon">
            <i className="fa-solid fa-cloud"></i>
          </div>
          <div className="card-title">Eliminate brain fog</div>
        </div>
        <div className="card random-image" style={{backgroundImage: "url('/img/food.jpg')"}}>

        </div>
        <div className="card">
          <div className="card-icon">
            <i className="fa-solid fa-wheat-awn-circle-exclamation"></i>
          </div>
          <div className="card-title">Solve digestive issues</div>
        </div>

        <div className="card">
          <div className="card-icon">
            <i className="fa-solid fa-shield"></i>
          </div>
          <div className="card-title">Enhance the immune system</div>
        </div>
        <div className="card">
          <div className="card-icon">
            <i className="fa-solid fa-bed"></i>
          </div>
          <div className="card-title">Better sleep</div>
        </div>
        <div className="card">
          <div className="card-icon">
            <i className="fa-solid fa-fire-flame-simple"></i>
          </div>
          <div className="card-title">Reduce stress</div>
        </div>
      </div>
    </Default>
  );
};

export default Labs;
