import { useEffect } from 'react';
import Default from '../components/layout/Default';
import { Link } from 'react-router-dom';

const Meet = (props) => {
  useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Default backgroundImage="/img/meet-lisa.jpg">
      <div className="pagetitle quote">
        <h3>“The roots to good health is living life in balance”</h3>
      </div>
      <div className="meet-lisa-portrait-container">
        <img className="meet-lisa-portrait" src="/img/meet-lisa-portrait.jpg" alt="Lisa Portrait" />
      </div>
      <h1>Meet Lisa...</h1>
      <div>
        <p>Hi there I am the founder of Roots To Wellness.</p>
        <p>I am an Integrated health practitioner (IHP1,2)</p>
      </div>
      <p>Let me begin by telling you little about myself.</p>
      <p>
        I was raised and nurtured in Scotland by my unique parents with Welsh/English vegetable, garden backgrounds. I was encouraged to love and embrace the natural
        world. I was brought up a vegetarian. I was a healthy kid growing up, encouraged by my parents to explore the world around me: cycling; hiking through glens and
        hills, embracing wildlife.
      </p>
      <p>
        It was a great start to life with a healthy lifestyle and wholesome organic meals made from my mother’s kitchen. I am forever grateful to my parents for teaching
        me what is truly important to be healthy and happy in life.
      </p>
      <p>
        As a child I was very shy. I chose to observe people’s characters rather than talk. I was very intuitive and empathetic. I felt emotions deeply from others around
        me. As I grew up, I realized I had a gift. I have used these gifts to manoeuvre through my life.
      </p>
      <h3 className="quote center">
        “The Garden of the world has no limits, except in your mind”
        <br />
        Rumi
      </h3>
      <p>
        I am a passionate person with an innate, creative, artistic and scientific mind. l loved my previous occupation as a Physiotherapist (RPT) for over 22 years. My
        healing specialities included :electro acupuncture for acute/chronic pain, nutrition for cellular repair, massage and manual therapy.
      </p>
      <p>I am now a Certified Integrated Health Coach/Practitioner and have been practising for 2 years.</p>
      <p>
        I provide wellness plans after consultations with my clients for rebalancing their health problems. I can also interpret my clients functional lab biomarkers .
      </p>
      <p>
        I have a keen spirit that has tapped into the world of energy, attending courses on Indigenous Healing Methods (Toltec Traditional and Andean Traditions), Reiki,
        meditation, therapeutic massage and kundalini yoga . I’ve also worked with essential oils and medicinal plants for healing.
      </p>
      <p>
        I have survived a very rare form of cancer called CNS lymphoma in 2018. I used many natural products, herbs and alternative treatments to enhance my immune system
        to help my body heal better from the cancer cells. I believe this approach enhanced my immune system. After my remission of cancer I had been left with a hearing
        impairment and a balance disorder. Today I live in daily gratitude healing from cancer. This experience has influenced my decisions and choices in my daily life
        enormously.
      </p>
      <div className="center">
        <Link to="/my-roots-to-wellness-journey">
          <button style={{marginTop: -4}}>Roots to Wellness Journey</button>
        </Link>
      </div>
      <div className="center quote">
        <h3 className="hide-on-small">“The roots of true achievement lie in the will<br/>to become the best that you can become”</h3>
        <h4 className="hide-on-large">“The roots of true achievement lie in the will to become the best that you can become”</h4>
        <h3 className="hide-on-small">Rumi</h3>
        <h4 className="hide-on-large">Rumi</h4>
      </div>
      <p>
        My disabilities forced me to leave my passion as a physiotherapist. This was a very sad moment in my life. I loved my job, working and facilitating healing with
        my clients. After healing from cancer for two years. I pursued my life’s passion to help educate people on their health and wellbeing.
      </p>
      <p>I know the experience of being a patient with chronic illness has helped me understand what my own clients may experience in their own health problems.</p>
      <p>
        I searched and found Dr Stephen Cabral’s website on becoming an Integrated health Practitioner. Seriously, this was a very challenging course for me to embark on,
        due to my impaired hearing. However once I had established a platform that provided me captions for all Dr Cabral’s video learning tools I managed remarkably.
      </p>
      <p>
        I am a curious and inquisitive person by nature. I like to ask questions in life; to dig further, deeper into the roots of why that person behaves the way they
        do. I have always sensed peoples essences by observation and vibration. bearing in mind my characteristics, I feel they will lend themselves well in helping my
        clients investigate the roots of their imbalanced health.
      </p>
      <p>
        I feel with these life experiences and my passion for helping others, my work as an Integrative Health Practitioner will inspire my clients to work with me for
        their future Optimum Wellness and Longevity.
      </p>
      <div className="quote center">
        <h3>“When we strive to become better than we are,</h3>
        <h3>everything around us becomes better too”</h3>
        <h3>Paulo Coelho</h3>
      </div>
    </Default>
  );
};

export default Meet;
