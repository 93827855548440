import Home from "../screens/Home";
import Meet from "../screens/Meet";
import Services from "../screens/Services";
import FAQ from "../screens/FAQ";
import Contact from "../screens/Contact";
import Labs from "../screens/Labs";
import Roots from "../screens/Roots";

const routes = [
  {
    path: "",
    component: Home,
    name: "Home Page",
    protected: false,
  },
  {
    path: "/meet-lisa",
    component: Meet,
    name: "Meet Lisa",
    protected: false,
  },
  {
    path: "/services",
    component: Services,
    name: "Services",
    protected: false,
  },
  {
    path: "/faq",
    component: FAQ,
    name: "FAQ",
    protected: false,
  },
  {
    path: "/contact",
    component: Contact,
    name: "Contact",
    protected: false,
  },
  {
    path: "/functional-labs",
    component: Labs,
    name: "Labs",
    protected: false,
  },
  {
    path: "/my-roots-to-wellness-journey",
    component: Roots,
    name: "Roots",
    protected: false,
  },
];

export default routes;
