import { useEffect } from 'react';
import Default from '../components/layout/Default';
import './home.scss';
import { Link } from 'react-router-dom';

const Home = (props) => {
  useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Default backgroundImage="/img/homepage.jpg">
      
      <div className="pagetitle quote">
        <h3>“Wellbeing - in all its expressions emerges with balance”</h3>
        <h3>“Illness stems from the imbalance at its roots”</h3>
      </div>
      <h1>Roots to Wellness...</h1>
      <p className='hide-on-small'>is a place where your wellbeing will be supported, guided, and empowered<br/>for your optimum wellness.</p>
      <p className='hide-on-large'>is a place where your wellbeing will be supported, guided, and empowered for your optimum wellness.</p>
      <p>
        When I work together with my clients as a Certified Health Coach I steer you to a platform where I can investigate the root cause of disharmony of all elements of
        you. Symptoms expressed by your body's imbalances will assist me in finding the underlying issues. Signs and symptoms are indicative of deeper disturbances in the
        chemistry of the body. These symptoms may show up as fatigue, low mood, exhaustion, brain fog, low libido and skin rashes to name but a few. As an Integrated
        Health Practitioner I will delve further into your living and working environments, your diet, daily exercise, daily stressors, exposure to toxins and sleep
        problems to account for the symptoms presented. This is known as the DESTRESS protocol.
      </p>
      <div className='destress section'>
        <h4 style={{fontSize: '1.5em', fontWeight: 800}}>DESTRESS</h4>
        <p>Areas I will discuss in consultations that come up as body imbalances</p>
        <p>
          <strong>D</strong>iet <strong>E</strong>xercise <strong>S</strong>tress <strong>T</strong>oxins <strong>R</strong>est/<strong>S</strong>leep <strong>E</strong>motions{' '}
          <strong>S</strong>upplements <strong>S</strong>uccess
        </p>
      </div>
      <p>
        During our first consultation I will review the information from your health/lifestyle questionnaire and toxicity quiz that I sent you, before our consultation. I
        will ask,
      </p>
      <div className='section center quote'>
        <p><strong>“If you could change one thing in your health today</strong></p>
        <p><strong>what would that be?”</strong></p>
      </div>
      <p>
        The answer to this question will start the process for me to prioritize your number one health concern. I will also continue to sift through your information,
        identifying any symptoms of deficiencies and toxicities creating unhealthy imbalances.
      </p>
      <p>
        I will recommend completing a detoxification program, supplements if needed for deficiences, health advice and some lifestyle changes to start the process of
        healing and balancing your body's ailments.
      </p>
      <p>I will also offer you a lab test if you are interested in hard data of your biomarkers.</p>
      <p>A Complete Natural Wellness Plan will be created and tailored to your specific needs empowering you to reach your optimum wellness.</p>
      <div className='center'><Link to="/contact"><button>Request a <span className='hide-on-small'>free 15 mins</span> consultation</button></Link></div>
    </Default>
  );
};

export default Home;
